import { memo, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Button } from "reactstrap";
import { JobService } from "services/Employer/Listing/Job";
import SignaturePad from "signature_pad";
import Docxtemplater from "docxtemplater";
import PizZip from "pizzip";
import classes from "./styles.module.scss";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import ImageModule from "docxtemplater-image-module-free";
import { saveAs } from "file-saver";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
import { XeroContactService } from "services/Xero/XeroContact";
import moment from "moment";

interface SignTermProps {
  onCloseSignTermModal: () => void;
  handleAcceptTerm: () => Promise<void>;
  // Add these props
  contractor?: any;
  contractData?: any;
  jobId?: number;
}

const SignTerm = memo((props: SignTermProps) => {
  const { onCloseSignTermModal, handleAcceptTerm, contractor, contractData, jobId } = props;
  const { user } = useSelector((state: ReducerType) => state.user);

  const dispatch = useDispatch();
  const { setting } = useSelector((state: ReducerType) => state.employer);

  const [signature, setSignature] = useState(null);

  useEffect(() => {
    const canvas = document.getElementById("canvas");
    function resizeCanvas() {
      // When zoomed out to less than 100%, for some very strange reason,
      // some browsers report devicePixelRatio as less than 1
      // and only part of the canvas is cleared then.
      var ratio = Math.max(window.devicePixelRatio || 1, 1);
      (canvas as any).width = canvas.offsetWidth * ratio;
      (canvas as any).height = canvas.offsetHeight * ratio;
      (canvas as any).getContext("2d").scale(ratio, ratio);
    }
    if (canvas) {
      window.onresize = resizeCanvas;
      resizeCanvas();
      const signaturePad = new SignaturePad(canvas as any, {
        backgroundColor: "#ffffff",
        penColor: "#000000",
      });
      document.getElementById("clear").addEventListener("click", () => {
        signaturePad.clear();
      });
      document.getElementById("undo").addEventListener("click", () => {
        var data = signaturePad.toData();
        if (data) {
          data.pop();
          signaturePad.fromData(data);
        }
      });
      document
        .getElementById("submit-contract")
        .addEventListener("click", () => {
          const data = signaturePad.toDataURL("image/png");
          setSignature(data);
        });
    }
  }, []);

  const handleSubmit = (res) => {
    return new Promise(() => {
      const myFile = new File([res as BlobPart], "Terms-Of-Engagement.docx", {
        type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      });
      const base64Regex = /^data:image\/(png|jpg|svg|svg\+xml);base64,/;
      function base64Parser(dataURL) {
        if (typeof dataURL !== "string" || !base64Regex.test(dataURL)) {
          return false;
        }
        const stringBase64 = dataURL.replace(base64Regex, "");
        const binaryString = window.atob(stringBase64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; i++) {
          const ascii = binaryString.charCodeAt(i);
          bytes[i] = ascii;
        }
        return bytes.buffer;
      }
      const imageOpts = {
        getImage(tag) {
          return base64Parser(tag);
        },
        getSize() {
          return [110, 60];
        },
      };
      var reader = new FileReader();
      reader.readAsBinaryString(myFile);
      reader.onload = async function (evt) {
        const content = evt.target.result;
        var zip = new PizZip(content);
        var doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
        });
        doc.render({
          Client: `${setting?.firstName} ${setting?.lastName}`,
          Company: setting?.name,
          ContractorName: contractor 
            ? `${contractor?.firstName} ${contractor?.lastName}` 
            : "",
          ContractorNumber: contractor?.mobile ?? "",  // or wherever you store contractor phone
          Title:        contractData?.title ?? "",
          StartDate:    contractData?.startDate 
            ? moment(contractData.startDate).format("DD/MM/YYYY") 
            : "",
          EndDate:      contractData?.endDate 
            ? moment(contractData.endDate).format("DD/MM/YYYY") 
            : "",
          Rate:         contractData?.rateOffered ?? "",
          RateType:     "Per Hour", 
          ManagerName:  contractData?.managerName ?? "",
          ApproverName: contractData?.approvalName ?? "",
          ApproverEmailAddress: contractData?.approvalEmail ?? "",
          PurchaseOrderNumber: contractData?.purchaseOrderNumber ?? "",
        });
        doc = new Docxtemplater(zip, {
          paragraphLoop: true,
          linebreaks: true,
          modules: [new ImageModule(imageOpts)],
          delimiters: { start: "[", end: "]" },
        });
        await doc.renderAsync({
          signature,
        });
        var blob = doc.getZip().generate({
          type: "blob",
          mimeType:
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          compression: "DEFLATE",
        });
        await JobService.postContract(
          blob,
          `${user?.id}-${jobId}-${S3_UPLOAD_TYPE_PRIFIX.TERMS_OF_ENGAGEMENT}`
        )
          .then(async () => {
            const myTermsOfBusiness = new File(
              [blob],
              "SignedTermOfEngagement.docx",
              {
                type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              }
            );
            await handleAcceptTerm();
            saveAs(myTermsOfBusiness, "SignedTermOfEngagement.docx");
          })
          .catch((e) => dispatch(setErrorMess(e)));
      };
    });
  };

  const createOrUpdateXeroAccount = () => {
    XeroContactService.putEmployerContact(user?.id, user?.id, {
      name: setting?.name,
      firstName: setting?.firstName,
      lastName: setting?.lastName,
      emailAddress: setting?.email,
      contactNumber: user?.id,
      addresses: [
        {
          AddressType: "STREET",
          City: setting?.city,
          Region: setting?.region,
          Country: setting?.country,
        },
        {
          AddressType: "POBOX",
          City: setting?.city,
          Region: setting?.region,
          Country: setting?.country,
        },
      ],
      phones: [
        {
          PhoneType: "DEFAULT",
          PhoneNumber: setting?.workPhone,
          PhoneCountryCode: setting?.workPhoneCountryCode || "+64",
        },
        {
          PhoneType: "DDI",
          PhoneNumber: setting?.workPhone,
          PhoneCountryCode: setting?.workPhoneCountryCode || "+64",
        },
        {
          PhoneType: "MOBILE",
          PhoneNumber: setting?.workPhone,
          PhoneCountryCode: setting?.workPhoneCountryCode || "+64",
        },
        {
          PhoneType: "FAX",
          PhoneNumber: setting?.workPhone,
          PhoneCountryCode: setting?.workPhoneCountryCode || "+64",
        },
      ],
    }).catch((e) => dispatch(setErrorMess(e)));
  };

  const handleSubmitContract = async () => {
    dispatch(setLoading(true));
    try {
      await createOrUpdateXeroAccount();
  
      const template = await JobService.getTemplate("Terms-Of-Engagement.docx");
      await handleSubmit(template);
    } catch (error) {
      dispatch(setErrorMess(error));
    } finally {
      dispatch(setLoading(false)); // Ensure loading is reset
    }
  };

  useEffect(() => {
    if (signature) {
      handleSubmitContract();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signature]);

  return (
    <Card className={classes.card}>
      <CardBody className={classes.cardBody}>
        <CardTitle className={classes.title}>SIGN HERE</CardTitle>
        <div className={classes.commonTitle}>
        By signing this Terms of Engagement, you acknowledge and agree to the contract details previously provided. A copy of the signed Terms of Engagement will be automatically downloaded for your records upon completion.
         </div> 
      </CardBody>

      <CardBody className={classes.canvasContainer}>
        <canvas
          id="canvas"
          className={classes.canvas}
          width={350}
          height={200}
        />
      </CardBody>
      <div className={classes.canvasButtons}>
        <Button size="sm" color="info" type="button" id="undo">
          Undo
        </Button>
        <Button size="sm" color="info" type="button" id="clear">
          Clear
        </Button>
      </div>
      <CardBody className={classes.cardBody}>
        <div className={classes.buttonContainer}>
          <Button
            size="md"
            color="default"
            type="button"
            className={classes.btn}
            onClick={onCloseSignTermModal}
          >
            Back
          </Button>
          <Button
            id="submit-contract"
            size="md"
            color="primary"
            type="button"
            className={classes.btn}
          >
            Submit
          </Button>
        </div>
      </CardBody>
    </Card>
  );
});

export default SignTerm;
