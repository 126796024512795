import { memo, useMemo, useState, useEffect } from "react";
import {
  Form,
  Card,
  CardBody,
  Modal,
  Row,
  Col,
  ModalHeader,
  Button,
} from "reactstrap";
import classes from "./styles.module.scss";
import moment from "moment";
import { setErrorMess, setLoading } from "redux/reducers/Status/actionTypes";
import { JobService } from "services/Employer/Listing/Job";
import CustomInput from "components/Common/CustomInput";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { ReducerType } from "redux/reducers";
import CustomDatePicker from "components/Common/CustomDatePicker";
import clsx from "clsx";
import SignTerms from "./SignTerms";
import PizZip from "pizzip";
import Docxtemplater from "docxtemplater";
import { S3_UPLOAD_TYPE_PRIFIX } from "config/constants";
import { setJobOffersReducer } from "redux/reducers/Employer/actionTypes";
import { EmailTypes } from "models/Employer/Listings/Dashboard";
import { JobService as ManageJobService } from "services/Employer/Manage/Job";

interface EditExtendContractProps {
  onClose: () => void;
  isOpen: boolean;
  contract?: any;
  onCloseContractModal?: () => void;
  downloadContract?: () => void;
}

const EditExtendContract = memo(
  ({
    onClose,
    isOpen,
    contract,
    onCloseContractModal,
    downloadContract,
  }: EditExtendContractProps) => {
    const dispatch = useDispatch();
    const { user } = useSelector((state: ReducerType) => state.user);
    const { setting } = useSelector((state: ReducerType) => state.employer);
    const { jobOffers } = useSelector((state: ReducerType) => state.employer);
    const [termData, setTermData] = useState({
      startDate: null,
      endDate: null,
      rateOffered: null,
      approvalName: null,
      approvalEmail: null,
      purchaseOrderNumber: null,
    });

    const contractKey = `${contract?.job}-${contract?.contractor}-${S3_UPLOAD_TYPE_PRIFIX.JOB_CONTRACT}`;

    const [modalSignTerms, setModalSignTerms] = useState(false);

    const handleShowSignTermModal = () => {
      setModalSignTerms(!modalSignTerms);
    };

    const handleOpenSignTermsModal = (data) => {
      setModalSignTerms(true);
    };

    const onCloseModelSignTerms = () => {
      setModalSignTerms(false);
    };

    const [editItems, setEditItems] = useState({
      endDate: false,
      rateOffered: false,
      approvalName: false,
      approvalEmail: false,
      purchaseOrder: false,
    });

    const handleToggleEditEndDate = () => {
      setEditItems({
        ...editItems,
        endDate: !editItems?.endDate,
      });
    };

    const handleToggleEditRateOffered = () => {
      setEditItems({
        ...editItems,
        rateOffered: !editItems?.rateOffered,
      });
    };

    const handleToggleEditApprovalName = () => {
      setEditItems({
        ...editItems,
        approvalName: !editItems?.approvalName,
        approvalEmail: !editItems?.approvalEmail,
      });
    };

    const handleToggleEditPurchaseOrder = () => {
      setEditItems({
        ...editItems,
        purchaseOrder: !editItems?.purchaseOrder,
      });
    };

    const schema = useMemo(() => {
      return yup.object().shape({
        newEndDate: editItems?.endDate
          ? yup
              .date()
              .nullable()
              .typeError("Please enter a valid date")
              .required("This field is required")
          : yup.date().nullable(),
        newRate: editItems?.rateOffered
          ? yup
              .number()
              .nullable()
              .typeError("Please enter a valid number")
              .required("This field is required")
          : yup.number().nullable(),
        newApprovalName: editItems?.approvalName
          ? yup.string().nullable().required("This field is required")
          : yup.string(),
        newApprovalEmail: editItems?.approvalName
          ? yup.string().nullable().required("This field is required")
          : yup.string(),
        newPurchaseOrder: editItems?.purchaseOrder
          ? yup.string().nullable().required("This field is required")
          : yup.string(),
      });
    }, [
      editItems?.endDate,
      editItems?.rateOffered,
      editItems?.approvalName,
      editItems?.purchaseOrder,
    ]);

    const {
      register,
      control,
      watch,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        newEndDate: null,
        newRate: null,
        newApprovalEmail: contract?.approvalEmail || "",
        newApprovalName: contract?.approvalName || "",
        newPurchaseOrder: "",
      },
    });

    const handleSendContract = async (dataSubmit) => {
      await JobService.postContractJobOffer(dataSubmit)
        .then(async (res) => {
          dispatch(setJobOffersReducer([...jobOffers, { ...res }]));
          await ManageJobService.extendContractEmailToContractor(res?.id, {
            type: EmailTypes.EXTEND_JOB_OFFER_CONTRACTOR,
          })
            .then(() => {
              onClose();
            })
            .catch((e) => dispatch(setErrorMess(e)));
        })
        .catch((e) => dispatch(setErrorMess(e)));
    };

    const handleAcceptTerm = async (data) => {
      try {
        // For example, compute contract duration
        const duration = moment(data?.endDate).diff(moment(data?.startDate), "days");
        
        // Prepare what you need to send to the backend
        const requestData = {
            jobId: contract?.job,
            contractorId: contract?.contractor,
            Name: `${contract?.firstName} ${contract?.lastName}`,
            Client: setting?.name,
            Contractor: `${contract?.firstName} ${contract?.lastName}`,
            Title: contract?.title,
            Department_Project: contract?.client,
            NameOfContractor: contract?.firstName,
            Lead: contract?.managerName,
            CurrentDate: moment(new Date()).format("DD/MM/YYYY"),
            StartDate: moment(data?.startDate).format("YYYY-MM-DD"),
            EndDate: moment(data?.endDate).format("YYYY-MM-DD"),
            RATE: data?.rateOffered,
            DURATION: duration,
            T_RATE: data?.rateOffered * 8 * duration,
            contractUrl: `${contractKey}.docx`, // File key for S3 storage
          // More data as needed by your backend...
        };

        // 2) Call your backend to create the .docx and store it in S3
        const response = await JobService.createContract(requestData);
        // The response might include a property like response.contractUrl

        // After backend has created and uploaded the docx, finalize in your DB
        await handleSendContract({
          contractor: contract?.contractor,
          employer: user?.id,
          job: contract?.job,
          startDate: contract?.startDate,
          endDate: data?.endDate,
          rateOffered: data?.rateOffered,
          contractUrl: response?.contractUrl, // returned from your API
          managerName: contract?.managerName,
          approvalName: data?.approvalName,
          approvalEmail: data?.approvalEmail,
          purchaseOrderNumber: data?.purchaseOrderNumber,
          offered: true,
          revoked: false,
          rejected: false,
          accepted: false,
          isContraContractUsed: true,
          isExtendContract: true,
        });
      } catch (error) {
        dispatch(setErrorMess(error));
      }
    };

    useEffect(() => {
      reset();
    }, [isOpen, reset]);

    useEffect(() => {
      reset({
        newEndDate: !editItems?.endDate ? null : watch("newEndDate"),
        newRate: !editItems?.rateOffered ? null : watch("newRate"),
        newApprovalName: !editItems?.approvalName ? "" : contract?.approvalName,
        newApprovalEmail: !editItems?.approvalEmail
          ? ""
          : contract?.approvalEmail,
        newPurchaseOrder: !editItems?.purchaseOrder
          ? ""
          : watch("newPurchaseOrder"),
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      editItems?.endDate,
      editItems?.rateOffered,
      editItems?.approvalName,
      editItems?.purchaseOrder,
    ]);

    const onSubmit = (data) => {
      if (editItems?.rateOffered || editItems?.endDate) {
        //extend and edit
        handleOpenSignTermsModal(data);
        setTermData({
          startDate: moment().toDate(),
          endDate: data?.newEndDate || contract?.endDate,
          rateOffered: data?.newRate || contract?.rateOffered,
          approvalName: data?.newApprovalName || contract?.approvalName,
          approvalEmail: data?.newApprovalEmail || contract?.approvalEmail,
          purchaseOrderNumber:
            data?.newPurchaseOrder || contract?.purchaseOrderNumber,
        });
      } else if (editItems?.approvalName || editItems?.purchaseOrder) {
        //edit
        dispatch(setLoading(true));
        JobService.putContractJobOffer(
          {
            contractor: contract?.contractor,
            employer: user?.id,
            job: contract?.job,
            startDate: contract?.startDate,
            endDate: contract?.endDate,
            rateOffered: contract?.rateOffered,
            approvalName: data?.newApprovalName,
            approvalEmail: data?.newApprovalEmail,
            purchaseOrderNumber: data?.newPurchaseOrder,
          },
          contract?.id
        )
          .then(() => {
            downloadContract && downloadContract();
          })
          .catch((err) => dispatch(setErrorMess(err)))
          .finally(() => {
            dispatch(setLoading(false));
            onClose();
            onCloseContractModal();
          });
      } else {
        onClose();
      }
    };

    return (
      <Card className={classes.card}>
        <Modal
          isOpen={isOpen}
          toggle={onClose}
          centered
          scrollable
          className={classes.modal}
        >
          <ModalHeader toggle={onClose} className={classes.modalHeader}>
            Extend/Edit Contract
          </ModalHeader>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <CardBody className={classes.cardDateBody}>
              <Row className="my-2">
                <Col className="d-flex" xs={5}>
                  <p className={classes.commonTitle}>Name:</p>
                </Col>
                <Col className="d-flex" xs={7}>
                  <p className={classes.commonTitle}>
                    {contract?.contractorDetails?.firstName}{" "}
                    {contract?.contractorDetails?.lastName}
                  </p>
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="d-flex" xs={5}>
                  <p className={classes.commonTitle}>Start Date:</p>
                </Col>
                <Col className="d-flex" xs={7}>
                  <p className={classes.commonTitle}>
                    {moment(contract?.startDate).format("DD/MM/YYYY")}
                  </p>
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="d-flex" xs={5}>
                  <p className={classes.commonTitle}>End Date:</p>
                </Col>
                <Col className="d-flex" xs={5}>
                  <p className={classes.commonTitle}>
                    {moment(contract?.endDate).format("DD/MM/YYYY")}
                  </p>
                </Col>
                <Col className="d-flex" xs={2}>
                  <p
                    className={classes.editTitle}
                    onClick={() => handleToggleEditEndDate()}
                  >
                    Edit{" "}
                    {!editItems?.endDate ? (
                      <i className="now-ui-icons arrows-1_minimal-down"></i>
                    ) : (
                      <i className="now-ui-icons arrows-1_minimal-up"></i>
                    )}
                  </p>
                </Col>
              </Row>
              {editItems?.endDate && (
                <Row className="my-2">
                  <Col className="d-flex offset-1" xs={4}>
                    <p className={classes.editItem}>New End Date:</p>
                  </Col>
                  <Col className="d-flex" xs={6}>
                    <CustomDatePicker
                      className={clsx(classes.input)}
                      placeholder="New end date"
                      name="newEndDate"
                      control={control}
                      minDate={moment(contract?.endDate)
                        .add(1, "days")
                        .toDate()}
                      dateFormat="dd/MM/yyyy"
                      errorMessage={errors.newEndDate?.message}
                    />
                  </Col>
                </Row>
              )}

              <Row className="my-2">
                <Col className="d-flex" xs={5}>
                  <p className={classes.commonTitle}>Rate Offered:</p>
                </Col>
                <Col className="d-flex" xs={5}>
                  <p className={classes.commonTitle}>
                    ${contract?.rateOffered}
                  </p>
                </Col>
                <Col className="d-flex" xs={2}>
                  <p
                    className={classes.editTitle}
                    onClick={() => handleToggleEditRateOffered()}
                  >
                    Edit{" "}
                    {!editItems?.rateOffered ? (
                      <i className="now-ui-icons arrows-1_minimal-down"></i>
                    ) : (
                      <i className="now-ui-icons arrows-1_minimal-up"></i>
                    )}
                  </p>
                </Col>
              </Row>
              {editItems?.rateOffered && (
                <>
                  <Row className="my-2">
                    <Col className="d-flex offset-1" xs={9}>
                      <p className={classes.descriptionEdit}>
                        NB: This will only be applicable from after the original
                        End Date
                      </p>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col className="d-flex offset-1" xs={4}>
                      <p className={classes.editItem}>New Rate:</p>
                    </Col>
                    <Col className="d-flex" xs={6}>
                      <CustomInput
                        className={classes.input}
                        placeholder="New rate"
                        autoComplete="off"
                        inputRef={register("newRate")}
                        errorMessage={errors.newRate?.message}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row className="my-2">
                <Col className="d-flex" xs={5}>
                  <p className={classes.commonTitle}>Manager Name:</p>
                </Col>
                <Col className="d-flex" xs={7}>
                  <p className={classes.commonTitle}>{contract?.managerName}</p>
                </Col>
              </Row>
              <Row className="my-2">
                <Col className="d-flex" xs={5}>
                  <p className={classes.commonTitle}>Approval Name:</p>
                </Col>
                <Col className="d-flex" xs={5}>
                  <p className={classes.commonTitle}>
                    {contract?.approvalName}
                  </p>
                </Col>
                <Col className="d-flex" xs={2}>
                  <p
                    className={classes.editTitle}
                    onClick={() => handleToggleEditApprovalName()}
                  >
                    Edit{" "}
                    {!editItems?.approvalName ? (
                      <i className="now-ui-icons arrows-1_minimal-down"></i>
                    ) : (
                      <i className="now-ui-icons arrows-1_minimal-up"></i>
                    )}
                  </p>
                </Col>
              </Row>
              {editItems?.approvalName && (
                <>
                  <Row className="my-2">
                    <Col className="d-flex offset-1" xs={4}>
                      <p className={classes.editItem}>New Approvers Name:</p>
                    </Col>
                    <Col className="d-flex" xs={6}>
                      <CustomInput
                        className={classes.input}
                        placeholder="New approval name"
                        autoComplete="off"
                        inputRef={register("newApprovalName")}
                        errorMessage={errors.newApprovalName?.message}
                      />
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col className="d-flex offset-1" xs={4}>
                      <p className={classes.editItem}>New Approvers Email:</p>
                    </Col>
                    <Col className="d-flex" xs={6}>
                      <CustomInput
                        className={classes.input}
                        placeholder="New approval email"
                        autoComplete="off"
                        inputRef={register("newApprovalEmail")}
                        errorMessage={errors.newApprovalEmail?.message}
                      />
                    </Col>
                  </Row>
                </>
              )}
              <Row className="my-2">
                <Col className="d-flex" xs={5}>
                  <p className={classes.commonTitle}>Purchase Order No:</p>
                </Col>
                <Col className="d-flex" xs={5}>
                  <p className={classes.commonTitle}>
                    {contract?.purchaseOrderNumber}
                  </p>
                </Col>
                <Col className="d-flex" xs={2}>
                  <p
                    className={classes.editTitle}
                    onClick={() => handleToggleEditPurchaseOrder()}
                  >
                    Edit{" "}
                    {!editItems?.purchaseOrder ? (
                      <i className="now-ui-icons arrows-1_minimal-down"></i>
                    ) : (
                      <i className="now-ui-icons arrows-1_minimal-up"></i>
                    )}
                  </p>
                </Col>
              </Row>
              {editItems?.purchaseOrder && (
                <Row className="my-2">
                  <Col className="d-flex offset-1" xs={4}>
                    <p className={classes.editItem}>New Order No:</p>
                  </Col>
                  <Col xs={6}>
                    <CustomInput
                      className={classes.input}
                      placeholder="New purchase order"
                      autoComplete="off"
                      inputRef={register("newPurchaseOrder")}
                      errorMessage={errors.newPurchaseOrder?.message}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col className={classes.buttonActionContainer}>
                  <Button color="primary" type="submit">
                    Extend/Edit
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Form>
        </Modal>

        <Modal
          isOpen={modalSignTerms}
          toggle={handleShowSignTermModal}
          className={classes.termModal}
        >
          <SignTerms
            onCloseSignTermModal={handleShowSignTermModal}
            handleAcceptTerm={handleAcceptTerm}
            contractData={termData}
            jobId={contract?.job}
          />
        </Modal>
      </Card>
    );
  }
);

export default EditExtendContract;
